var mySwiper = new Swiper('.swiper-container', {
    loop: true,
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});

$(function () {
    var $btn = $('.js-header__btn');
    if ($btn.length) {
        var $nav = $('.js-header__nav'),
            hasShow = $btn.hasClass('show'),
            isShowing = false;

        $btn.click(function () {
            if (isShowing) return;
            hasShow = !hasShow;

            if (hasShow) {
                $btn.addClass('show').find('.fa').removeClass('fa-bars').addClass('fa-close');
                $nav.addClass('show');
                $nav.height(0)[0].offsetHeight;
                isShowing = true;
                $nav.height($nav[0].scrollHeight);
                setTimeout(function () {
                    isShowing = false;
                }, 350);
            } else {
                $btn.removeClass('show').find('.fa').removeClass('fa-close').addClass('fa-bars');
                $nav.height(0);
                isshowing = true;
                setTimeout(function () {
                    $nav.removeClass('show');
                    isShowing = false;
                }, 350);
            }
        });
    }
});

$(function () {
    var $btn = $('.js-second-nav-btn');
    var $nav = $('.js-second-nav');
    var leaveTimer = null;

    $btn.hover(
        function () {
            if (leaveTimer) {
                clearTimeout(leaveTimer);
                leaveTimer = null;
                return;
            }
            $nav.height($nav[0].scrollHeight);
            $nav.addClass('show');
        },
        function () {
            leaveTimer = setTimeout(() => {
                leaveTimer = null;
                $nav.height(0);
                $nav.removeClass('show');
            }, 0);
        }
    );
    $nav.hover(
        function () {
            if (leaveTimer) {
                clearTimeout(leaveTimer);
                leaveTimer = null;
                return;
            }
            $nav.height($nav[0].scrollHeight);
            $nav.addClass('show');
        },
        function () {
            leaveTimer = setTimeout(() => {
                leaveTimer = null;
                $nav.height(0);
                $nav.removeClass('show');
            }, 0);
        }
    );
});
